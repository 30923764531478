import BannerContent from '#/components/shared/ui/banner/banner-content';
import { StyledBanner } from '#/components/shared/ui/banner/styles';
import BannerImage from '#/components/shared/ui/banner/banner-image';

interface BannerProps {
  title: string;
  description: string;
  image: string;
}

export default function Banner({ title, description, image }: BannerProps) {
  return (
    <StyledBanner>
      <BannerContent title={title} description={description} />
      <BannerImage image={image} />
    </StyledBanner>
  );
}
