import { Stack, Typography } from '@mui/material';
import {
  SituationCardContent,
  SituationCardContentColumns,
} from '#/components/shared/ui/situation-card/styles';

interface Column {
  title: string;
  description: string[];
}

type ContentColumnsProps = {
  columns: Column[];
};

const ContentColumns = ({ columns }: ContentColumnsProps) => (
  <SituationCardContentColumns>
    {columns.map((column, index) => (
      <SituationCardContent key={index}>
        <Typography variant="h6">{column.title}</Typography>
        {column?.description?.map((desc, descIndex) => (
          <Stack
            key={descIndex}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            {column.title === 'Ihre Vorteile' && <Typography>•</Typography>}
            <Typography>{desc}</Typography>
          </Stack>
        ))}
      </SituationCardContent>
    ))}
  </SituationCardContentColumns>
);

export default ContentColumns;
