import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type LastWillSituationKeys =
  | 'heirat'
  | 'geburt-eines-kindes'
  | 'pensionierung'
  | 'krankheit'
  | 'unternehmensgruendung'
  | 'scheidung';

export const LAST_WILL_COMMONS = {
  service: 'testament',
  actions: defaultActions,
  learnMoreLink: '/testament',
  startProcessLink: '/dashboard/testament',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-tes.webp',
  },
};

export const LAST_WILL_SITUATION: Record<LastWillSituationKeys, any> = {
  heirat: {
    content: {
      main: {
        title: 'Testament',
        description:
          'Mit einem Testament legen Sie fest, was mit Ihrem Vermögen im Todesfall geschieht. Sie können sicherstellen, dass Ihr Ehepartner und Ihre Familie abgesichert sind und Ihr Wille respektiert wird.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Ein Testament gibt Ihnen die Sicherheit, dass Ihr Vermögen nach Ihren Wünschen verteilt wird und Ihr Ehepartner rechtlich abgesichert ist.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Absicherung des Ehepartners',
            'Klare Erbregelungen',
            'Vermeidung von Streitigkeiten',
          ],
        },
      ],
    },
  },
  'geburt-eines-kindes': {
    content: {
      main: {
        title: 'Testament',
        description:
          'Mit einem Testament legen Sie fest, wie Ihr Vermögen im Todesfall aufgeteilt wird. Sie können sicherstellen, dass Ihr Kind finanziell abgesichert ist und Ihre Wünsche respektiert werden.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Ein Testament gibt Ihnen die Sicherheit, dass Ihr Kind im Falle Ihres Todes gut versorgt ist und dass Ihre Vermögenswerte nach Ihren Wünschen verteilt werden.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Finanzielle Sicherheit für Partner/in und Familie',
            'Rechtliche Klarheit über Erbfolgen',
            'Schutz des Familienvermögens',
          ],
        },
      ],
    },
  },
  pensionierung: {
    content: {
      main: {
        title: 'Testament',
        description:
          'Mit einem Testament legen Sie fest, wie Ihr Vermögen nach Ihrem Tod aufgeteilt wird. Sie sorgen dafür, dass Ihre Liebsten nach Ihren Wünschen abgesichert sind.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Im Rentenalter ist es wichtig, die Nachlassplanung in die Hand zu nehmen, um Erbstreitigkeiten zu vermeiden und Ihre Familie abzusichern.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klarheit über die Vermögensverteilung',
            'Absicherung der Familie',
            'Vermeidung von Streitigkeiten',
          ],
        },
      ],
    },
  },
  krankheit: {
    content: {
      main: {
        title: 'Testament',
        description:
          'Mit einem Testament bestimmen Sie, wie Ihr Vermögen nach Ihrem Tod aufgeteilt wird. So sorgen Sie dafür, dass Ihre Liebsten abgesichert sind und Ihr letzter Wille respektiert wird.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Eine schwere Diagnose macht deutlich, wie schnell sich das Leben verändern kann. Ein Testament sorgt dafür, dass Ihre Familie finanziell abgesichert ist und Streitigkeiten vermieden werden.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Absicherung Ihrer Familie',
            'Klarheit über die Erbverteilung',
            'Schutz des Vermögens',
          ],
        },
      ],
    },
  },
  unternehmensgruendung: {
    content: {
      main: {
        title: 'Testament',
        description:
          'Mit einem Testament regeln Sie, was mit Ihrem Unternehmen und Vermögen im Todesfall geschieht. So stellen Sie sicher, dass Ihr Erbe nach Ihren Wünschen verwaltet und Ihr Unternehmen fortgeführt wird.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Gerade als Firmengründer ist es wichtig, frühzeitig Vorkehrungen zu treffen. Mit einem Testament sichern Sie den Fortbestand Ihres Unternehmens und verhindern rechtliche Auseinandersetzungen.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klare Erbregelungen für das Unternehmen',
            'Vermeidung von Streitigkeiten',
            'Schutz des Familienvermögens und Unternehmenswerts',
          ],
        },
      ],
    },
  },
  scheidung: {
    content: {
      main: {
        title: 'Testament',
        description:
          'Mit einem Testament legen Sie fest, wie Ihr Vermögen im Todesfall aufgeteilt wird. Gerade nach einer Scheidung sollten Sie sicherstellen, dass Ihre Erbschaftsregelungen Ihre neuen Lebensumstände widerspiegeln.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Eine Scheidung verändert Ihre Familien- und Vermögensverhältnisse. Ein (aktualisiertes) Testament sorgt dafür, dass Ihre Wünsche auch nach der Trennung respektiert werden.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klarheit über die Verteilung Ihres Vermögens',
            'Schutz Ihrer Liebsten',
            'Anpassung an die veränderte Familiensituation',
          ],
        },
      ],
    },
  },
} as const;

export const getLastWillSituation = (situation: LastWillSituationKeys) => ({
  ...LAST_WILL_SITUATION[situation],
  ...LAST_WILL_COMMONS,
});
