import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type AssetsRegisterSituationKeys = 'pensionierung' | 'tod-verwandter';

export const ASSETS_REGISTER_COMMONS = {
  service: 'vermoegensregister',
  actions: defaultActions,
  learnMoreLink: '/vermoegens-und-sachregister',
  startProcessLink: '/dashboard/vermoegens-und-sachregister',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-assets.webp',
  },
};

export const ASSETS_REGISTER_SITUATION: Record<
  AssetsRegisterSituationKeys,
  any
> = {
  pensionierung: {
    content: {
      main: {
        title: 'Vermögens- und Sachregister',
        description:
          'Ein Vermögens- und Sachregister dokumentiert Ihre Besitztümer und Wertgegenstände. So schaffen Sie Transparenz und stellen sicher, dass alles, was Ihnen wichtig ist, in guten Händen bleibt.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Nach dem Tod wird die Aufteilung von Vermögen und Besitztümern oft zur Belastung. Mit einem Vermögensregister beugen Sie Unklarheiten vor und sorgen dafür, dass alles nach Ihren Wünschen geregelt wird.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klarheit über Vermögenswerte und Besitztümer',
            'Schutz vor Streitigkeiten',
            'Ordnung und Transparenz für Ihre Familie',
          ],
        },
      ],
    },
  },

  'tod-verwandter': {
    content: {
      main: {
        title: 'Vermögens- und Sachregister',
        description:
          'Ein Vermögens- und Sachregister dokumentiert Ihre Besitztümer und Wertgegenstände. So schaffen Sie Transparenz und stellen sicher, dass alles, was Ihnen wichtig ist, in guten Händen bleibt.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Nach dem Tod eines Verwandten wird die Aufteilung von Vermögen und Besitztümern oft zur Belastung. Mit einem Vermögensregister beugen Sie Unklarheiten vor und sorgen dafür, dass alles nach Ihren Wünschen geregelt wird.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klarheit über Vermögenswerte und Besitztümer',
            'Schutz vor Streitigkeiten',
            'Ordnung und Transparenz für Ihre Familie',
          ],
        },
      ],
    },
  },
} as const;

export const getAssetsRegisterSituation = (
  situation: AssetsRegisterSituationKeys
) => ({
  ...ASSETS_REGISTER_SITUATION[situation],
  ...ASSETS_REGISTER_COMMONS,
});
