import { Button, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { SituationCardActions } from '#/components/shared/ui/situation-card/styles';

type CardActionsProps = {
  actions: {
    learnMore: string;
    startProcess: string;
  };
  learnMoreLink?: string;
  startProcessLink: string;
  modal?: boolean;
};

const CardActions = ({
  actions,
  learnMoreLink,
  startProcessLink,
  modal,
}: CardActionsProps) => (
  <SituationCardActions>
    {learnMoreLink && (
      <Link component={RouterLink} to={learnMoreLink}>
        <Button variant="outlined" color="primary">
          <Typography>{actions.learnMore}</Typography>
        </Button>
      </Link>
    )}
    <Link
      component={RouterLink}
      to={startProcessLink}
      state={{
        modal,
      }}
    >
      <Button variant="contained" color="primary">
        <Typography>{actions.startProcess}</Typography>
      </Button>
    </Link>
  </SituationCardActions>
);

export default CardActions;
