import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  SituationCardContainer,
  SituationCardContent,
  SituationCardContentContainer,
  SituationCardMedia,
  StyledSituationCard,
} from '#/components/shared/ui/situation-card/styles';
import Image from '#/components/shared/ui/Image';
import { Situation } from '#/components/shared/ui/situation-card/types';
import ContentColumns from '#/components/shared/ui/situation-card/content-columns';
import CardActions from '#/components/shared/ui/situation-card/card-actions';

type SituationCardProps = {
  situation: Situation;
  order?: number;
};

export default function SituationCard({
  order,
  situation,
}: SituationCardProps) {
  const [cardService, setCardService] = useState<string>('');

  const { service, content, media, actions, learnMoreLink, startProcessLink } =
    situation;

  const location = useLocation();

  const url = location.pathname.split('/').pop();

  const isNonDefault = localStorage.getItem('gg_partner_key') !== null;
  const isFinancialPlanning = service === 'finanzplanung';
  const startProcess =
    isNonDefault && isFinancialPlanning ? '/dashboard' : startProcessLink;

  const secondPOAImage =
    'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-poa2.webp';

  useEffect(() => {
    setCardService(service);
  }, [service]);

  const handleImageChange = () => {
    const isPOA = cardService === 'vorsorgeauftrag';
    const isPension = url === 'pensionierung';

    if (isPOA && isPension) {
      return secondPOAImage;
    }
    return media.image;
  };

  return (
    <StyledSituationCard>
      <SituationCardContainer>
        <SituationCardContentContainer>
          <SituationCardContent>
            <Typography variant="h3" component="h6">
              {order}. {content.main.title}
            </Typography>
            <Typography>{content.main.description}</Typography>
          </SituationCardContent>
          <ContentColumns columns={content.columns} />
          <CardActions
            learnMoreLink={learnMoreLink}
            startProcessLink={startProcess}
            actions={actions}
            modal={isFinancialPlanning && isNonDefault}
          />
        </SituationCardContentContainer>
        {media.image && (
          <SituationCardMedia>
            <Image src={handleImageChange()} alt="Situation card image" />
          </SituationCardMedia>
        )}
      </SituationCardContainer>
    </StyledSituationCard>
  );
}
