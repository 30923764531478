import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const LandingContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1264,
  margin: '0 auto',
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),

    gap: theme.spacing(10),
  },
}));
