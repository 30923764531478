import { Situation } from '#/components/shared/ui/situation-card/types';
import { getLivingWillSituation } from '#/components/pages/LSLandings/constants/living-will';
import { getMarriageContractSituation } from '#/components/pages/LSLandings/constants/marriage-contract';
import { getLastWillSituation } from '#/components/pages/LSLandings/constants/last-will';
import { getPOASituation } from '#/components/pages/LSLandings/constants/poa';
import { getDigitalFootprintSituation } from '#/components/pages/LSLandings/constants/digital-footprint';
import { getFinancialPlanningSituation } from '#/components/pages/LSLandings/constants/financial-planning';
import { getAssetsRegisterSituation } from '#/components/pages/LSLandings/constants/assets-register';

type LsLandingContent = {
  [key: string]: {
    bannerImg: string;
    bannerTitle: string;
    bannerDescription: string;
    sectionHeadline?: string;
    cards: Situation[];
  };
};

const landingContent: LsLandingContent = {
  heirat: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/vorsorge-marriage.webp',
    bannerTitle: 'Heiraten heisst Verantwortung  übernehmen. Und vorsorgen.',
    bannerDescription:
      'Heiraten bedeutet, Verantwortung zu übernehmen. Für sich selbst, für Ihren Ehepartner, für Ihre Familie. Wir helfen Ihnen, in guten Zeiten für die schlechten Zeiten vorzusorgen.',
    sectionHeadline: 'Empfohlene Vorsorge bei der Heirat',
    cards: [
      getMarriageContractSituation('heirat'),
      getLivingWillSituation('heirat'),
      getLastWillSituation('heirat'),
    ],
  },

  'geburt-eines-kindes': {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/birthOfChild.webp',
    bannerTitle: 'Ein Kind bedeutet Liebe. Und Verantwortung für die Zukunft.',
    bannerDescription:
      'Die Geburt eines Kindes ist ein wunderbares Ereignis, welches das Leben auf den Kopf stellt. Jetzt ist der perfekte Zeitpunkt, die schönen Momente abzusichern und langfristig zu denken. Gemeinsam sorgen wir dafür, dass Ihr Kind bzw. Ihre Kinde und Ihre Liebsten immer bestens versorgt sind – egal, was das Leben bringt.',
    sectionHeadline: 'Empfohlene Vorsorge bei der Geburt eines Kindes',
    cards: [
      getPOASituation('geburt-eines-kindes'),
      getLastWillSituation('geburt-eines-kindes'),
      getMarriageContractSituation('geburt-eines-kindes'),
      getLivingWillSituation('geburt-eines-kindes'),
    ],
  },

  immobilienkauf: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/purchaseOfRealEstate.webp',
    bannerTitle:
      'Ein Eigenheim schafft Sicherheit. Sorgen Sie vor, damit das so bleibt.',
    bannerDescription:
      'Der Kauf eines Eigenheims ist ein grosser Schritt in die Zukunft. Damit diese Geborgenheit auch in schwierigen Zeiten bestehen bleibt, ist es wichtig, rechtzeitig vorzusorgen. Wir helfen Ihnen, Ihre Vorsorgepläne auf die neue Lebenssituation anzupassen, damit Sie sich ganz auf Ihr neues Zuhause konzentrieren können.',
    sectionHeadline: 'Empfohlene Vorsorge beim Kauf einer Immobilie',
    cards: [
      getPOASituation('immobilienkauf'),
      getMarriageContractSituation('immobilienkauf'),
      getLivingWillSituation('immobilienkauf'),
    ],
  },

  pensionierung: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/enteringRetirement.webp',
    bannerTitle:
      'Entspannt in den Ruhestand. Sorgen Sie vor, um in Ruhe geniessen zu können.',
    bannerDescription:
      'Mit dem Eintritt ins Rentenalter beginnt ein neuer Lebensabschnitt. Gerade jetzt ist es wichtig, über die Zukunft nachzudenken. Wir helfen Ihnen dabei, alles in die richtigen Bahnen zu lenken, damit Sie Ihren Ruhestand unbeschwert geniessen können.',
    sectionHeadline: 'Empfohlene Vorsorge beim Eintritt in die Rente',
    cards: [
      getLivingWillSituation('pensionierung'),
      getLastWillSituation('pensionierung'),
      getPOASituation('pensionierung'),
      getDigitalFootprintSituation('pensionierung'),
      getFinancialPlanningSituation('pensionierung'),
      getAssetsRegisterSituation('pensionierung'),
    ],
  },

  'tod-verwandter': {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/deathOfARelative.webp',
    bannerTitle:
      'Vorsorge schafft Ruhe und Raum für Trauer bei einem Todesfall.',
    bannerDescription:
      'Der Tod eines Verwandten ist eine schwere Zeit, in der vieles geregelt werden muss. Neben der Trauer gibt es oft auch Fragen zum digitalen Nachlass und den materiellen Werten des Verstorbenen. Mit einem klaren Vermögens- und Sachregister sowie einer Regelung für den digitalen Nachlass schaffen Sie Ordnung und helfen Ihren Hinterbliebenen, alles in Ihrem Sinne zu verwalten.',
    sectionHeadline: 'Empfohlene Vorsorge bei einem Todesfall',
    cards: [
      getDigitalFootprintSituation('tod-verwandter'),
      getAssetsRegisterSituation('tod-verwandter'),
      getLivingWillSituation('tod-verwandter'),
    ],
  },

  unternehmensgruendung: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/foundingACompany.webp',
    bannerTitle: 'Selbstständig durchstarten. Und gut abgesichert bleiben.',
    bannerDescription:
      'Mit der Gründung eines Unternehmens setzen Sie Ihre Vision in die Tat um und schaffen etwas von bleibendem Wert. Doch gerade in dieser aufregenden Zeit ist es wichtig, sich abzusichern – sowohl persönlich als auch geschäftlich. Mit einem Vorsorgeauftrag und einem Testament stellen Sie sicher, dass Ihr Unternehmen und Ihre Familie auch dann geschützt sind, wenn das Leben unvorhergesehene Wendungen nimmt.',
    sectionHeadline: 'Empfohlene Vorsorge bei einer Firmengründung',
    cards: [
      getPOASituation('unternehmensgruendung'),
      getLastWillSituation('unternehmensgruendung'),
      getDigitalFootprintSituation('unternehmensgruendung'),
      getLivingWillSituation('unternehmensgruendung'),
    ],
  },

  scheidung: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/divorce.webp',
    bannerTitle:
      'Eine Scheidung bringt Veränderungen. Sorgen Sie dafür, dass Ihre Zukunft gesichert bleibt.',
    bannerDescription:
      'Eine Scheidung kann eine herausfordernde Zeit sein. Es ist wichtig, dass Sie in dieser Phase gut informiert sind und die richtigen Entscheidungen treffen. Wir helfen Ihnen, alle notwendigen Regelungen zu treffen, damit Sie und Ihre Familie gut abgesichert sind und einen Neuanfang wagen können.',
    sectionHeadline: 'Empfohlene Vorsorge bei einer Scheidung',
    cards: [
      getPOASituation('scheidung'),
      getFinancialPlanningSituation('scheidung'),
      getLastWillSituation('scheidung'),
    ],
  },

  krankheit: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/illness.webp',
    bannerTitle:
      'Eine schwere Diagnose bringt Unsicherheit. Wir helfen Ihnen vorzusorgen.',
    bannerDescription:
      'Eine schwere Krankheit verändert alles. In dieser schwierigen Zeit ist es umso wichtiger, sich auf das Wesentliche zu konzentrieren – Ihre Gesundheit und die Menschen, die Ihnen am Herzen liegen. Mit einer Patientenverfügung und einem Testament sorgen Sie dafür, dass Ihre Wünsche respektiert und Ihre Liebsten abgesichert sind, wenn Sie selbst es nicht mehr regeln können.',
    sectionHeadline: 'Empfohlene Vorsorge bei Krankheitsdiagnose',
    cards: [
      getLivingWillSituation('krankheit'),
      getLastWillSituation('krankheit'),
    ],
  },
};

export default landingContent;
