import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type FinancialPlanningSituationKeys = 'pensionierung' | 'scheidung';

export const FINANCIAL_PLANNING_COMMONS = {
  service: 'finanzplanung',
  actions: defaultActions,
  startProcessLink:
    'https://finfinder.ch/?openModal=1&utm_source=gutgeregelt&utm_medium=app&utm_campaign=promo',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-finances2.webp',
  },
};

export const FINANCIAL_PLANNING_SITUATION: Record<
  FinancialPlanningSituationKeys,
  any
> = {
  pensionierung: {
    content: {
      main: {
        title: 'Beratung zur Finanzsituation',
        description:
          'Im Ruhestand ändert sich oft die finanzielle Situation. Unsere Beratung hilft Ihnen, Ihre Finanzen optimal zu planen, damit Sie auch im Alter finanziell abgesichert sind.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Eine gute Finanzplanung gibt Ihnen im Ruhestand Sicherheit und sorgt dafür, dass Sie Ihre neue Lebensphase entspannt genießen können.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Optimale Nutzung Ihrer Renteneinkünfte',
            'Steuerliche Vorteile',
            'Langfristige finanzielle Absicherung',
          ],
        },
      ],
    },
  },

  scheidung: {
    content: {
      main: {
        title: 'Finanzplanung',
        description:
          'Eine umfassende Finanzplanung hilft Ihnen, Ihre finanzielle Situation nach der Scheidung neu zu ordnen und langfristig abzusichern. So behalten Sie die Kontrolle über Ihre Finanzen.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Nach einer Scheidung müssen finanzielle Prioritäten oft neu gesetzt werden. Eine professionelle Finanzplanung gibt Ihnen Klarheit und Sicherheit für die kommenden Jahre.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Neuordnung Ihrer finanziellen Situation',
            'Planung für langfristige finanzielle Stabilität',
            'Steuerliche Optimierung nach der Scheidung',
          ],
        },
      ],
    },
  },
} as const;

export const getFinancialPlanningSituation = (
  situation: FinancialPlanningSituationKeys
) => ({
  ...FINANCIAL_PLANNING_SITUATION[situation],
  ...FINANCIAL_PLANNING_COMMONS,
});
