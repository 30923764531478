import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type DigitalFootprintSituationKeys =
  | 'pensionierung'
  | 'tod-verwandter'
  | 'unternehmensgruendung';

export const DIGITAL_FOOTPRINT_COMMONS = {
  service: 'digitaler-nachlass',
  actions: defaultActions,
  learnMoreLink: '/digitaler-nachlass',
  startProcessLink: '/dashboard/digitaler-nachlass',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-df.webp',
  },
};

export const DIGITAL_FOOTPRINT_SITUATION: Record<
  DigitalFootprintSituationKeys,
  any
> = {
  'tod-verwandter': {
    content: {
      main: {
        title: 'Digitaler Nachlass',
        description:
          'Der digitale Nachlass regelt, was mit Ihren Online-Konten, sozialen Netzwerken und digitalen Daten nach Ihrem Tod geschieht. Sie bestimmen, was gelöscht, übertragen oder weitergeführt werden soll.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'In der heutigen Zeit hinterlassen wir eine Vielzahl an digitalen Spuren. Indem Sie Ihren digitalen Nachlass regeln, verhindern Sie, dass sensible Daten unkontrolliert im Netz bleiben und entlasten Ihre Angehörigen.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Kontrolle über Ihre digitalen Daten',
            'Schutz vor Identitätsmissbrauch',
            'Entlastung Ihrer Angehörigen',
          ],
        },
      ],
    },
  },

  pensionierung: {
    content: {
      main: {
        title: 'Digitaler Nachlass',
        description:
          'Ihr digitales Leben hinterlässt Spuren – von Online-Accounts bis zu Social Media. Mit einer Regelung für Ihren digitalen Nachlass bestimmen Sie, was nach Ihrem Tod mit Ihren Daten geschieht.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Im digitalen Zeitalter ist es wichtig, auch den digitalen Nachlass zu regeln, um den Umgang mit sensiblen Daten zu sichern.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Schutz Ihrer digitalen Identität',
            'Vermeidung von Missbrauch',
            'Entlastung der Angehörigen',
          ],
        },
      ],
    },
  },

  unternehmensgruendung: {
    content: {
      main: {
        title: 'Digitaler Nachlass',
        description:
          'Der digitale Nachlass regelt, was mit Ihren Online-Konten, sozialen Netzwerken und digitalen Daten nach Ihrem Tod geschieht. Sie bestimmen, was gelöscht, übertragen oder weitergeführt werden soll.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'In der heutigen Zeit hinterlassen wir eine Vielzahl an digitalen Spuren. Indem Sie Ihren digitalen Nachlass regeln, verhindern Sie, dass sensible Daten unkontrolliert im Netz bleiben und entlasten Ihre Angehörigen.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Kontrolle über Ihre digitalen Daten',
            'Schutz vor Identitätsmissbrauch',
            'Entlastung Ihrer Angehörigen',
          ],
        },
      ],
    },
  },
} as const;

export const getDigitalFootprintSituation = (
  situation: DigitalFootprintSituationKeys
) => ({
  ...DIGITAL_FOOTPRINT_SITUATION[situation],
  ...DIGITAL_FOOTPRINT_COMMONS,
});
