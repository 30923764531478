import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type LivingWillSituationKeys =
  | 'heirat'
  | 'geburt-eines-kindes'
  | 'immobilienkauf'
  | 'pensionierung'
  | 'krankheit'
  | 'tod-verwandter'
  | 'unternehmensgruendung'
  | 'scheidung';

export const LIVING_WILL_COMMONS = {
  service: 'patientenverfügung',
  actions: defaultActions,
  learnMoreLink: '/patientenverfuegung',
  startProcessLink: '/dashboard/patientenverfuegung',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-lw.webp',
  },
};

export const LIVING_WILL_SITUATION: Record<LivingWillSituationKeys, any> = {
  heirat: {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Mit einer Patientenverfügung stellen Sie sicher, dass Ihre medizinischen Wünsche im Ernstfall respektiert werden. Sie entscheiden, welche Behandlungen Sie erhalten möchten und welche nicht.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Im Alter nehmen gesundheitliche Unsicherheiten zu. Eine Patientenverfügung gibt Ihnen die Sicherheit, dass Ihre Entscheidungen auch dann respektiert werden, wenn Sie sie nicht mehr selbst treffen können.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Selbstbestimmung im Ernstfall',
            'Entlastung der Angehörigen',
            'Rechtliche Sicherheit',
          ],
        },
      ],
    },
  },

  'geburt-eines-kindes': {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Eine Patientenverfügung legt fest, welche medizinischen Entscheidungen in Ihrem Namen getroffen werden sollen, wenn Sie nicht mehr dazu in der Lage sind. So sichern Sie Ihre Selbstbestimmung und entlasten Ihre Familie.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Mit der Geburt eines Kindes wächst die Verantwortung. Eine Patientenverfügung stellt sicher, dass Ihre medizinischen Wünsche respektiert werden und Ihre Familie in schwierigen Situationen klare Anweisungen hat.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Selbstbestimmung im medizinischen Bereich',
            'Entlastung Ihrer Angehörigen',
            'Schutz und klare Regelungen für die Familie',
          ],
        },
      ],
    },
  },

  immobilienkauf: {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Mit einer Patientenverfügung legen Sie fest, welche medizinischen Massnahmen im Ernstfall durchgeführt oder unterlassen werden sollen. So behalten Sie die Kontrolle über Ihre medizinische Versorgung, selbst wenn Sie nicht mehr selbst entscheiden können.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Gerade bei langfristigen Verpflichtungen wie einem Eigenheim ist es wichtig, auch die persönliche Vorsorge im Blick zu haben. Eine Patientenverfügung gibt Ihnen Sicherheit, dass Ihre medizinischen Wünsche respektiert werden und Ihr Zuhause geschützt bleibt.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Sicherstellung Ihrer medizinischen Wünsche',
            'Entlastung Ihrer Angehörigen',
            'Ergänzung zum Schutz Ihrer persönlichen und finanziellen Werte',
          ],
        },
      ],
    },
  },

  pensionierung: {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Mit einer Patientenverfügung stellen Sie sicher, dass Ihre medizinischen Wünsche im Ernstfall respektiert werden. Sie entscheiden, welche Behandlungen Sie erhalten möchten und welche nicht.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Im Alter nehmen gesundheitliche Unsicherheiten zu. Eine Patientenverfügung gibt Ihnen die Sicherheit, dass Ihre Entscheidungen auch dann respektiert werden, wenn Sie sie nicht mehr selbst treffen können.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Selbstbestimmung im Ernstfall',
            'Entlastung der Angehörigen',
            'Rechtliche Sicherheit',
          ],
        },
      ],
    },
  },

  krankheit: {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Eine Patientenverfügung legt fest, welche medizinischen Massnahmen ergriffen oder unterlassen werden sollen, wenn Sie nicht mehr selbst entscheiden können.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Eine schwere Krankheit kann unerwartete Wendungen nehmen. Eine Patientenverfügung gibt Ihnen die Sicherheit, dass Ihre Behandlungswünsche respektiert werden und entlastet Ihre Angehörigen.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Selbstbestimmung in medizinischen Fragen',
            'Entlastung der Angehörigen',
            'Klarheit in schwierigen Situationen',
          ],
        },
      ],
    },
  },

  'tod-verwandter': {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Eine Patientenverfügung legt fest, welche medizinischen Massnahmen ergriffen oder unterlassen werden sollen, wenn Sie nicht mehr in der Lage sind, selbst zu entscheiden. Sie schaffen Klarheit und entlasten Ihre Familie in schwierigen Zeiten.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Der Verlust eines nahen Angehörigen macht deutlich, wie wichtig es ist, die eigenen Wünsche klar zu regeln. Mit einer Patientenverfügung sorgen Sie dafür, dass Ihre medizinischen Entscheidungen respektiert werden und Ihre Familie in Notsituationen nicht belastet wird.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Sicherstellung Ihrer medizinischen Wünsche',
            'Entlastung der Hinterbliebenen',
            'Klare Handlungsanweisungen für den Ernstfall',
          ],
        },
      ],
    },
  },

  unternehmensgruendung: {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Eine Patientenverfügung legt fest, welche medizinischen Massnahmen ergriffen oder unterlassen werden sollen, wenn Sie nicht mehr selbst entscheiden können.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Eine schwere Krankheit kann unerwartete Wendungen nehmen. Eine Patientenverfügung gibt Ihnen die Sicherheit, dass Ihre Behandlungswünsche respektiert werden und entlastet Ihre Angehörigen.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Selbstbestimmung in medizinischen Fragen',
            'Entlastung der Angehörigen',
            'Klarheit in schwierigen Situationen',
          ],
        },
      ],
    },
  },

  scheidung: {
    content: {
      main: {
        title: 'Patientenverfügung',
        description:
          'Mit einer Patientenverfügung bestimmen Sie selbst, welche medizinischen Entscheidungen getroffen werden sollen, wenn Sie nicht mehr dazu in der Lage sind. Nach einer Scheidung bietet sie Ihnen die nötige Unabhängigkeit und Selbstbestimmung.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Eine Scheidung verändert nicht nur Ihre rechtliche, sondern auch Ihre persönliche Situation. Eine Patientenverfügung stellt sicher, dass Ihre medizinischen Wünsche unabhängig von früheren familiären Bindungen respektiert werden.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Unabhängigkeit bei medizinischen Entscheidungen',
            'Schutz Ihrer Selbstbestimmung',
            'Entlastung Ihrer neuen Lebenspartner oder Angehörigen',
          ],
        },
      ],
    },
  },
} as const;

export const getLivingWillSituation = (situation: LivingWillSituationKeys) => ({
  ...LIVING_WILL_SITUATION[situation],
  ...LIVING_WILL_COMMONS,
});
