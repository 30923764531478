import { styled } from '@mui/material/styles';
import Image, { ImageProps } from '#/components/shared/ui/Image';

export const StyledBanner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 20,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const StyledBannerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 10,
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
}));

export const StyledBannerImage = styled(Image)<ImageProps>(({ theme }) => ({
  width: 360,
  height: 'auto',
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}));
