const metadata = {
  heirat: {
    title: 'Heirat: Diese Vorsorge ist jetzt wichtig',
    description:
      'Heiraten bedeutet, Verantwortung zu übernehmen. Für sich selbst, für Ihren Ehepartner, für Ihre Familie. Wir helfen Ihnen bei der nötigen Vorsorge.',
  },
  'geburt-eines-kindes': {
    title: 'Geburt eines Kindes: Diese Vorsorge ist jetzt wichtig',
    description:
      'Die Geburt eines Kindes ist ein wunderbares Ereignis. Jetzt ist der perfekte Zeitpunkt, die schönen Momente abzusichern und langfristig zu denken.',
  },
  immobilienkauf: {
    title: 'Kauf einer Immobilie: Diese Vorsorge ist jetzt wichtig',
    description:
      'Der Kauf eines Eigenheims ist ein grosser Schritt. Damit diese Geborgenheit auch in schwierigen Zeiten bestehen bleibt, ist es wichtig, rechtzeitig vorzusorgen.',
  },
  pensionierung: {
    title: 'Pensionierung: Diese Vorsorge ist jetzt wichtig',
    description:
      'Mit dem Eintritt ins Rentenalter beginnt ein neuer Lebensabschnitt. Gerade jetzt ist es wichtig, über die Zukunft nachzudenken.',
  },
  krankheit: {
    title: 'Diagnose einer Krankheit: Diese Vorsorge ist jetzt wichtig',
    description:
      'Eine schwere Krankheit verändert alles. Eine Patientenverfügung und ein Testament sorgen dafür, dass Ihre Wünsche respektiert und Ihre Liebsten abgesichert sind',
  },
  'tod-verwandter': {
    title: 'Todesfall: Diese Vorsorge ist jetzt wichtig',
    description:
      'Der Tod eines Verwandten ist eine schwere Zeit. Schaffen Sie Ordnung und helfen Sie Ihren Hinterbliebenen, alles in Ihrem Sinne zu verwalten.',
  },
  unternehmensgruendung: {
    title: 'Unternehmensgründung: Diese Vorsorge ist jetzt wichtig',
    description:
      'Mit der Gründung eines Unternehmens setzen Sie Ihre Vision in die Tat um und schaffen bleibenden Wert. In dieser aufregenden Zeit ist es wichtig, sich abzusichern',
  },
  scheidung: {
    title: 'Scheidung: Diese Vorsorge ist jetzt wichtig',
    description:
      'Eine Scheidung kann eine herausfordernde Zeit sein. Wir helfen Ihnen, alle notwendigen Regelungen zu treffen, damit Sie und Ihre Familie gut abgesichert sind.',
  },
};

export default metadata;
