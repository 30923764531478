import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type POASituationKeys =
  | 'geburt-eines-kindes'
  | 'immobilienkauf'
  | 'pensionierung'
  | 'unternehmensgruendung'
  | 'scheidung';

export const POA_COMMONS = {
  service: 'vorsorgeauftrag',
  actions: defaultActions,
  learnMoreLink: '/vorsorgeauftrag',
  startProcessLink: '/dashboard/vorsorgeauftrag',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-poa.webp',
  },
};

export const POA_SITUATION: Record<POASituationKeys, any> = {
  'geburt-eines-kindes': {
    content: {
      main: {
        title: 'Vorsorgeauftrag',
        description:
          'Ein Vorsorgeauftrag regelt, wer für Sie und Ihre Familie entscheidet, falls Sie selbst dazu nicht mehr in der Lage sind. Schützen Sie sich und Ihr Kind durch eine klare Regelung.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Mit der Geburt eines Kindes tragen Sie zusätzliche Verantwortung. Ein Vorsorgeauftrag stellt sicher, dass Ihr Kind auch im Ernstfall gut betreut und versorgt wird.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Selbst bestimmen, wer sich um Ihr Kind kümmert',
            'Rechtliche Sicherheit für Ihre Familie',
            'Schutz im Krankheits- oder Notfall',
          ],
        },
      ],
    },
  },

  immobilienkauf: {
    content: {
      main: {
        title: 'Vorsorgeauftrag',
        description:
          'Ein Vorsorgeauftrag regelt, wer sich um Ihr Eigentum und Ihre persönlichen Angelegenheiten kümmert, falls Sie dazu selbst nicht mehr in der Lage sind. Dies sichert Ihr Eigenheim und Ihre Familie ab.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Mit dem Kauf einer Immobilie steigt Ihre Verantwortung. Ein Vorsorgeauftrag sorgt dafür, dass Ihr Eigentum auch in Notsituationen geschützt bleibt und Ihre Wünsche umgesetzt werden.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Schutz Ihres Eigenheims bei Krankheit oder Unfall',
            'Absicherung Ihrer Familie',
            'Rechtliche Klarheit für den Ernstfall',
          ],
        },
      ],
    },
  },
  pensionierung: {
    content: {
      main: {
        title: 'Vorsorgeauftrag',
        description:
          'Der Vorsorgeauftrag legt fest, wer Ihre Angelegenheiten regelt, falls Sie dazu selbst nicht mehr in der Lage sind. So stellen Sie sicher, dass Ihr Leben und Ihr Vermögen nach Ihren Wünschen verwaltet werden.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Mit dem Rentenalter sollte auch geregelt sein, wer sich im Notfall um Ihre finanziellen und persönlichen Angelegenheiten kümmert.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klare Regelung für den Fall der Urteilsunfähigkeit',
            'Schutz von Vermögen und Rechten',
            'Entlastung der Familie',
          ],
        },
      ],
    },
  },

  unternehmensgruendung: {
    content: {
      main: {
        title: 'Vorsorgeauftrag',
        description:
          'Ein Vorsorgeauftrag regelt, wer Ihre geschäftlichen und privaten Angelegenheiten übernimmt, falls Sie selbst dazu nicht mehr in der Lage sind. So sichern Sie den Fortbestand Ihres Unternehmens und schützen Ihre Familie.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Als Unternehmer tragen Sie große Verantwortung – nicht nur für sich selbst, sondern auch für Ihre Mitarbeitenden und Geschäftspartner. Ein Vorsorgeauftrag stellt sicher, dass Ihre Firma auch in Krisenzeiten handlungsfähig bleibt.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Schutz des Unternehmens bei Krankheit oder Unfall',
            'Fortführung der Geschäftsführung im Ernstfall',
            'Entlastung der Familie',
          ],
        },
      ],
    },
  },

  scheidung: {
    content: {
      main: {
        title: 'Vorsorgeauftrag',
        description:
          'Ein Vorsorgeauftrag legt fest, wer Ihre persönlichen und finanziellen Angelegenheiten regelt, falls Sie dazu nicht mehr in der Lage sind. Gerade nach einer Scheidung ist es wichtig, diese Regelungen neu zu definieren.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Nach einer Scheidung ändern sich oft viele rechtliche Rahmenbedingungen. Ein aktualisierter Vorsorgeauftrag sorgt dafür, dass Ihre Wünsche auch in der neuen Lebenssituation berücksichtigt werden.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Neue Regelung Ihrer persönlichen Angelegenheiten',
            'Schutz im Krankheitsfall',
            'Klarheit und Sicherheit für die Zukunft',
          ],
        },
      ],
    },
  },
} as const;

export const getPOASituation = (situation: POASituationKeys) => ({
  ...POA_SITUATION[situation],
  ...POA_COMMONS,
});
